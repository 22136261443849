.__CardMain{
    background-color: #E9ECEF;
    color: black;
    display: inline-block;
    width: calc(16.66% - 1em);
    min-height: 2.5em;
    vertical-align: text-top;
    padding: 1.5em 0;
    border-radius: 8px;
    margin: 1em .5em;
    text-align: center;
}

.__CardTitle{
    width: 100%;
    font-size: .8em;
    font-weight: 600;
}

.__selectYear{
    text-align: center;
    border: none !important;
    border-color: white;
    z-index: 100;
    font-weight: bolder;
    border-bottom: #000 solid 2px !important;
    border-top: #000 solid 2px !important;
}

.__selectYear > div, .__selectYear:hover > div, .__selectYear:focus > div{
    border-color: white;
    box-shadow: none !important;
}

.__selectYear > div > div:nth-child(2){
    display: none;
}

.__selectYearRow{
    width: 100% !important;
    padding-left: .6em;
}

.__CardData{
    font-size: 2.441em;
    font-weight: bold;
    line-height: 1em;
}

.__CardData2{
    font-size: 1.563em;
    font-weight: bold;
    line-height: 1.57em;
}

.__secondTitle{
    color: #444B59;
    font-size: 1.563rem;
    font-weight: bold;
    margin-top: 1.5rem;
}

.__noText{
    color: transparent !important;
}

.__boldText{
    font-weight: bold;
}

  .__mainTableScrolls{
    overflow: auto;
    max-height: 400px;
    margin-right: .75em;
  }

  .__mainTableScrollsMainMonths, .__mainTableScrollsHeaders, .__mainTableScrollsData{
    display: flex;
  }
  .__mainTableScrollsMainMonths{
    position: sticky;
    top: 0;
    z-index: 4;
  }

  .__mainTableScrollsMainMonths > div:nth-child(1){
    position: sticky;
    left: 0;
    z-index: 5;
    top: 0;
    background-color: white;
    color: transparent;
  }
  .__mainTableScrollsMonths{
    max-width: 360px;
    min-width: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    border-bottom: #000 solid 2px;
    background-color: white;
  }

  .__mainTableScrollsMonths2{
    max-width: 330px;
    min-width: 330px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    border-bottom: #000 solid 2px;
    background-color: white;
  }

  .__contractorSize{
    max-width: 180px;
    min-width: 180px;
  }

  .__mainTableScrollsHeaderData{
    background-color: white;
    border-bottom: #000 solid 2px;
  }

  .__mainTableScrollsHeaders{
    position: sticky;
    top: 60px;
    z-index: 2;
  }

  .__mainTableScrollsHeaders > div:nth-child(1), .__mainTableScrollsData > div:nth-child(1){
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: white;
  }

  .__mainTableScrollsHeaders > div:nth-child(1){
    top: 60px;
    z-index: 10 !important;
  }

  .__mainTableScrollsHeaderContractor, .__mainTableScrollsDataContractor{
    max-width: 180px;
    min-width: 180px;
    padding: .3em .3em;
    border-bottom: #000 solid 2px;
  }

  .__mainTableScrollsHeaderContractor2, .__mainTableScrollsDataContractor2{
    max-width: 150px;
    min-width: 150px;
    padding: .3em .3em;
    border-bottom: #000 solid 2px;
  }


  .__mainTableScrollsHeaderData, .__mainTableScrollsDataData{
    max-width: 120px;
    min-width: 120px;
    padding: .3em .3em;
  }

  .__mainTableScrollsDataContractor,.__mainTableScrollsDataData,.__mainTableScrollsDataContractor2{
    border-bottom: rgba(0, 0, 0, 0.12) 1px solid;
    display: flex;
    align-items: center;
  }
  .__mainTableScrollsDataData{

    justify-content: center;
  }

  .__mainTableScrollsHeaderData, .__mainTableScrollsDataData{
    text-align: center;
  }









