.__generalInfoTitle{
  font-weight: bold;
}

.contract_info {
  height: 78px;
  width: 100%;
  margin: auto;
  background-color: #F9FBFC;
  border: none;
  border-radius: 12px;
  text-align: center;
  font-size: 14px;
  padding: 5px;
}

.tableItem {
  width: 100%;
  max-width: 24ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.__buttonsSaveCancel {
  margin-top: 2.5rem;
  float: right;
}

.__marginButtonsCancel {
  margin: 0px 1.5rem;
}

.__marginButtonsSave {
  margin: 0rem;
}


.__errorText{
  font-size: 16px;
  color: #FA1818;
  display: block;
  line-height: 15px;
  margin: 5px 0px;
}

.__rightIconsDownload {
  margin: 0px .5rem;
  transition: ease 1s all;
  width: 18px;
  height: 18px;
}
.__eyeIcon{
  width: 22px;
  height: 22px;
}

.__rightIconsDownload:hover{
  cursor: pointer;
  color: #444B59;
  width: 22px;
  height: 22px;
}
.__eyeIcon:hover{
  width: 24px;
  height: 24px;
}
