.__MainRowTitle{
    margin-bottom: .5rem;
}

.__MainTitle{
    color: #444B59;
    font-size: 1.563rem;
    font-weight: bold;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
}

.__firstRoute{
    color: #444B59;
    font-size: 1rem;
    font-weight: bold;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
}

.__separator{
    color: #444B59;
    font-size: 1.953rem;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
}

.__titlePart3{
    width: 15.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.__titlePart2, .__titlePart4{
    width: 2.813rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.__titlePart1, .__titlePart5{
    width: calc(50% - 10.6255rem);
    font-size: 1rem;
}
.__titlePart1{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 2rem;
    cursor: pointer;
}

.__titlePart1:hover{
    text-decoration: underline;
}
.__titlePart5{
    display: flex;
    align-items: center;
    padding-left: 2rem;
}
.__noLink:hover{
    text-decoration: none !important;
    cursor: inherit !important;
}