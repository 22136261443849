.__centerAll{
  text-align: center;
} 

.__bgWhite{
  background-color: white;
  width: 100%;
  height: 100vh;
}

.__invocieLogo{ 
  width: 22%;
  height: auto;
}

.__welcomeTitle{
  color: #1f1f1f;
  font-weight: 600;
  margin-bottom: 10px;
}
.__welcomeIcon{
  margin: 0px 15px 2px 0px;
}

.__container{
  text-align: left;
  width: 35%;
}

.__userInfo{
  color: #1f1f1f;
  font-size: 20px;
  font-weight: 700;
}

.__leftContainerText{
  font-size: 17px;
  color: #837E7E;
}

.__errorText{
  font-size: 16px;
  color: #FA1818;
  display: block;
  line-height: 15px;
  margin-bottom: 5px;
}

.__passwordRequirements{
  font-size: 13px;
  line-height: 0px;
  margin: 5px 0px 30px 20px;
}

.eyeDivBase{
  background-color: transparent;
  margin-top: -2.6rem;
  margin-left: calc(100% - 3em); 
} 

.eye {
  border: none;
}
.eye:hover{
  background-color: #f9fbfc;
  color: #9b9a9f;
} 
.eyeError{
  border: none;
  color: #FA1818;
} 

.eye_icon {
  background-color: transparent;
  cursor: pointer;
} 

.__correctIcon{
  color: #09B01A;
}

.__alertIcon{
  color: #FA1818;
}

.__buttonDiv{
  text-align: center;
  margin-top: 30px;
}

.__buttonSize{
  width: 100%;
}
