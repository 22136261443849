.__baseStructure {
    min-width: 12.5rem;
    min-height: 3rem;
    padding: .5rem 1rem;
    border-radius: 1.5rem;
    font-weight: 700;
    font-size: 1.125rem;
}

.__baseStructureLink {
    min-width: 7rem;
    min-height: 2rem;
    padding: .5rem 1rem;
    border-radius: 1.5rem;
    font-weight: 600;
    font-size: 1rem;
    transition: all .5s ease;
    border: none;
}

.__baseStructureLink {
    min-width: 7rem;
    min-height: 2rem;
    padding: .5rem 1rem;
    border-radius: 1.5rem;
    font-weight: 600;
    font-size: 1rem;
    border: none;
}

.__baseStructureBlack {
    background-color: #444B59;
    color: white;
    transition: ease .3s all;
    border: 2.5px solid #444B59;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, .3); 
}

.__baseStructureBlack:hover {
    box-shadow: 0px 8px 5px rgba(0, 0, 0, .3); 
}

.__baseStructureWhite {
    background-color: white;
    color: #444B59;
    transition: ease .3s all;
    border: 2.5px solid white;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, .3);
}

.__baseStructureWhite:hover {
    box-shadow: 0px 8px 5px rgba(0, 0, 0, .3); 
}

.__baseStructureLink {
    background-color: white;
    border: none;
    font-weight: 700;
    border-radius: 20px;
    padding: 5px 18px;
    transition: background-color 0.15s, color 0.15s;
}

.__baseStructureLink:hover {
    background-color: #7C7C7C;
    color: white;
    transition: background-color 0.15s, color 0.15s;
}

.__baseStructureStatusBtn {
    min-width: 9rem;
    min-height: 3rem;
    padding: .5rem 1rem;
    border-radius: 1.5rem;
    font-weight: 600;
    font-size: 1rem;
    margin-left: 16px;
}

.__baseStructureDisable{
    background-color: #e9ecef;
    border: #e9ecef 2.5px solid !important;
    box-shadow: 0px 0px 0px rgba(255, 0, 0, 0) !important; 
}

.__baseStructureDisable:hover{
    background-color: #e9ecef;
    border: #e9ecef 2.5px solid;
    cursor: not-allowed !important;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0); 
}