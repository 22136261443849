.radioItem {
    display: inline-block;
    position: relative;
    padding: 0 6px;
    margin: 10px 0 0;
}

.radioItem input[type='radio'] {
    display: none;
}

.radioItem label {
    color: #000000;
    font-weight: normal;
}

.radioItem label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 5px 0 0;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid #000000;
    background-color: transparent;
}

.radioItem input[type='radio']:checked+label:after {
    border-radius: 11px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 9px;
    left: 10px;
    content: " ";
    display: block;
    background: #000000;
}