.__boundComponnet{
    background-color: #00000066;
    width: 100%;
    height: 100%;
    z-index: 100;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.__modalContainer{
    background-color: white;
    padding: 1.5rem 2rem;
    max-width: 700px;
    width: 90%;
    border-radius: 12px;
}

.__title{
    font-size: 1.953rem;
    font-weight: bold;
}

.__text{
    font-size: 1.25rem;
    color: #837E7E;
}

.__textLeft{
    font-size: 1.25rem;
    color: #837E7E;
    text-align: left;
    float: left;
    width: 100%;
}

.__textDiv{
    margin-bottom: 1rem ;
}

.__textDivComplete{
    margin-bottom: .5rem;
}

.__textLeftLast{
    font-size: 1.25rem;
    color: #837E7E;
    text-align: left;
    float: left;
    width: 100%;
    margin-bottom: 1rem;
}

.__buttonMargin{
    margin-left: 1rem;
}

.err {
    color: #DD2E44;
    padding-left: 15px;
    text-align: left;
    float: left;
    width: 100%;
    margin-bottom: 1rem;
}
