.__baseStructure {
    padding: .8rem .8rem;
    color: #000000;
    border: none;
    width: 100%;
    border-radius: 12px;
}

.__baseStructureCorrect {
    background-color: #F9FBFC;
}

.__baseStructure:hover,
.__baseStructure:focus {
    box-shadow: inset 0 0 0 1px #68df68;
    outline: none;
}

.__baseStructureIncorrect {
    background-color: #ffffff !important;
    box-shadow: inset 0 0 0 1px #DD2E44 !important;
    color: #DD2E44;

}

.__baseStructureIncorrect::placeholder {
    color: #DD2E44;

}

.__baseStructureIncorrect:read-only {
    color: #DD2E44;

}

.__disabledInput:hover {
    box-shadow: none;
}

.__disabled {
    padding: .8rem .8rem;
    color: #000000;
    box-shadow: none;
    width: 100%;
    border-radius: 12px;
    pointer-events: none;
    background-color: #F9FBFC;
}

.__disabled:hover,
.__disabled:focus {
    box-shadow: none;
    outline: none;
    pointer-events: none;

}