.userTitle {
  font-size: 25px;
  font-weight: bold;
  margin-left: 22px;
}

.__MainContent {
  padding: 0px 0px 10px;
  max-width: inherit !important;
}

.__divCellTable {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -1rem;
  margin-top: .5rem;
  width: 100%;
  text-align: center;
}

.__divCellTableName {
  margin-left: -1rem;
  margin-top: .5rem;
  width: 100%;
}

.__divCellTableLeft{
  margin-left: -1rem;
  margin-top: .6rem;
}

.spanValueTableName{
  font-size: .8rem;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
 
}

.__tableActivityLog{
  margin-top: 1.5rem;
}

.spanValueTable {
  font-size: .8rem;
}

.__divHeaderButtons{
  display: flex;
}

.__buttonExport {
  background-color: #444B59;
  width: 2.813rem;
  height: 3rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  transition: ease .3s all;
  border: 2.5px solid #444B59;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, .3); 
}

.__buttonExport:hover {
  box-shadow: 0px 8px 5px rgba(0, 0, 0, .3); 
  color: white;
}


.__activeUser{
  font-weight: 800;
color: #09b01a;
}

.__inactiveUser{
  font-weight: 800;
  color: red;
}

.__actionMenuIteam {
  color: #1f1f1f;
  font-weight: 500;
}

.__actionMenuIcons {
  margin-right: 10px;
  color: #1f1f1f;
}

.__buttonActionsTable{
  background-color: white;
  color: rgba(0, 0, 0, 0.54);
  border: none;
}

.__menuListModal{
  background-color: white;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.4);
}

.__menuListItemModal{
  background-color: white;
  border: none !important;
  padding: .5rem 1rem;
  transition: all .2s ease;
}
.__menuListItemModal:hover{
  background-color: #ddd;
}
