.__baseStructure{
    padding: .3rem .2rem;
    color: #000000;
    border: none;
    width: 100%;
    border-radius: 12px;
    height: 3.063em;
}

.__baseStructureCorrect{
    background-color: #F9FBFC;
}

.__baseStructureIncorrect{
    background-color: #ffffff;
    box-shadow: inset 0 0 0 1px #DD2E44 !important;
}

.__baseStructure:hover, .__baseStructure:focus{
    box-shadow: inset 0 0 0 1px #68df68;
}   

.__baseStructureIncorrect > div > div > div{
    color: #DD2E44 !important
}
