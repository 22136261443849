.__mainView{
    margin-left: 230px;
    padding: 5px; 
    width: calc(100% - 13.75rem); 
    height: calc(100vh);
    overflow-y: auto;
}

.__mainViewComplete{
    margin-left: 80px;
    padding: 5px; 
    width: calc(100% - 4.375rem); 
    height: calc(100vh);
    overflow-y: auto;
}

.__animationMain{
    transition: all .2s ease;
    overflow-x: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, .7);
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    z-index: 1001;
    position: fixed;
    background-color: white !important;
    padding: 1.5rem 2rem 4rem;
}