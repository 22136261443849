@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
body{
    overflow-y: hidden;
    overflow-x: hidden;
}

*{
    font-family: 'Roboto', sans-serif !important;
    scrollbar-width: thin;
    scrollbar-color: #444B59 #D9D9D9;
}

*::-webkit-scrollbar {
    width: 14px;    
    height: 14px;
    border-radius: 20px;  
  }
  
  *::-webkit-scrollbar-track {
    background: #D9D9D9;
    width: 200px;        
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #444B59;    
    border-radius: 20px;  
    width: 200px;   
    border: 3px solid #D9D9D9; 
  }