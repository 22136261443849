
.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: #E9ECEF !important;
    width: 13.75rem;
    z-index: 999;
    padding: 0 .3rem;
  }

  
.sidebarHide {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #E9ECEF !important;
  width: 4.375rem;
  z-index: 999;
}
.linksMainDiv{
  margin-top: 1rem;
}
  .listGroupItem{
    border: 0px solid transparent !important;
    font-family: 'Roboto', sans-serif;
    font-size: .8rem;
    background-color: #E9ECEF !important;
    margin: .15rem -.2em !important;
    border-radius: .5rem !important;
  }

  .listGroupItemLogOut{
    border: 0px solid transparent !important;
    font-family: 'Roboto', sans-serif;
    font-size: .8rem;
    background-color: #E9ECEF !important;
    margin: 0.24rem 0 !important;
    cursor: pointer;
  }

  .__selectedLink{
    background-color: #444B59 !important;
  }

  .__marginLeftIconsShow{
    padding-left: 1.25rem;
  }
  .listGroupItem:hover{
    background-color: white !important;
    cursor: pointer;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  }

  .__iconSideBar{
    margin-right: 1rem;
  }
  
  .__iconSideBarRoutes{
    margin-right: .7rem;
    font-size:1.5rem;
    color: #000000;
  }

  .__iconSelected{
    color: white !important;
  }

  .listGroupItem:hover > .__iconSideBarRoutes{
    color:  #000000 !important;
  }

  .__openSidebarDivPosition{
    position: absolute !important;
    left: calc(230px - 3rem);
    width: 3rem;
    z-index: 1000;
    bottom: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s ease;
    background-color: white;
    padding: .5rem 0;
    transform: scaleX(-1);
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
    cursor: pointer;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  }

  .__leftPosition{
    left: calc(80px - 3rem);
    border-radius: 0px;
    transform: scaleX(1);
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .__logOutDivPosition {
    position: absolute !important;
    width: 100%;
    bottom: 2rem;
  }
  .__logoMainDiv{
    margin-top: 48px;
   text-align: center;
    width: 100%;
    height: 4.5rem !important;
  }

  .__userInfoDataMainDiv{
    margin-top: 2rem;
    position: absolute;
    width: 100%;
    height: 4.473rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .__userInfoDataMainDivCut{
    width: 60px;
  }

  .__linksNav{
    margin-top: 7rem;
  }

  .__titleLogo{
    color: #444B59;
    font-weight: bold;
    font-size: 3.052rem;
  }

  .__logoLogo{
    width: 60px !important;
    margin-left: 5px;
    margin-bottom: 8px;
  }

  .__divBorderLogo{
    border-bottom: 4px solid #444B59;
    width: 2rem;
    margin-left: calc(50% - 1rem );
  }

  .__infoNameDivMyUser{
    margin-bottom: -0.438rem;
    width: 100%;
  }

  .__infoRoleDivMyUser{
    border-top: #444B59 2px solid;
    width: calc(100% - 2rem);
  }

  .__logoDivMyUser, .__infoDivMyUser{
    display: inline-block;
    vertical-align: middle;
  }

  .__logoDivMyUser{
    width: 3rem;
  }

  .__logosideBarHide{
    width: 3rem;
    margin-left: 0.688rem;
  }

  .__infoDivMyUser{
      margin-top: -1.5rem;
      width:  calc(95% - 3rem);
      line-height: 1rem;
  }

  .__nameTextMyUser{
    font-size: .8rem;
  }

  .__roleTextMyUser{
    font-size: .8rem;
    font-weight: bold;
    text-transform: uppercase;
    position: absolute;
  }

  .animationSidebar{
    transition: all .2s ease;
  }

.__spanIconsSideBarNoSelected{
  color: #495057
}

.__spanIconsSideBarSelected{
  color: #fff;
  font-weight: bold;
}

.listGroupItem:hover > .__spanIconsSideBarSelected{
  color: #000000 !important;
}
.__alignIconSidebar{
  margin-left: .1rem;
}

.__marginLeftIcons{
  padding-left: 1.25rem;
  padding-top: .5rem !important;
}
.cutLink {
  width: 3.75rem !important;
  margin-left: 5px !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cutLink > .__iconSideBarRoutes {
  margin: 0 !important;
}

