.__baseStructure {
    padding: .6rem .8rem;
    color: #000000;
    border: none !important;
    width: 100%;
    min-height: 6rem;
    border-radius: 12px;
    resize: none;
}

.__baseStructureCorrect {
    background-color: #F9FBFC;
}

.__baseStructure:hover,
.__baseStructure:focus {
    outline: 1px solid #68df68;
}

.__baseStructureIncorrect {
    background-color: #ffffff !important;
    outline: 1px solid #DD2E44 !important;
}

.__baseStructureIncorrect::placeholder {
    color: #DD2E44;

}

.__disabledInput:hover {
    outline: none;
}

.__disabled {
    padding: .8rem .8rem;
    color: #000000;
    border: none;
    width: 100%;
    border-radius: 12px;
    pointer-events: none;
    background-color: #F9FBFC;

}

.__disabled:hover,
.__disabled:focus {
    border: none;
    outline: none;
    pointer-events: none;

}

.__characterCounter{
    color: #837E7E;
    font-size: .8rem;
    text-align: end;
    margin-top: -2rem;
    padding-right: 1rem;
    margin-bottom: .7rem;
}