@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.form {
    height: 595px;
    width: 458px;
}

.__App {
    background-color: white;
    text-align: center;
}

.btn_submit {
    width: 403px;
    height: 48px;
    background-color: #444B59;
    border: none;
    transition: 300ms;
    transition: ease .3s all;
    border: 2.5px solid #444B59;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, .3); 
    color: white;
}

.btn_submit:hover {
    box-shadow: 0px 8px 5px rgba(0, 0, 0, .3); 
    color: white;
}

.i_form {
    background-color: #F5F5F5;
    font-size: 1rem;
    width: 403px;
    height: 48px;
    border: none;
    border-radius: 8px;
    margin-bottom: 3px;
}

.i_form::placeholder {
    color: black;
}

.i_form_placeholder_changed {
    background-color: #F5F5F5;
    font-size: 1rem;
    width: 403px;
    height: 48px;
    border-radius: 8px;
    border: 1px;
    border-color: #df4759;
    margin-bottom: 3px;
}

.i_form_placeholder_changed::placeholder {
    color: #df4759;
}


.container {
    font-family: 'Roboto', sans-serif;
}

.card {
    border-radius: 16px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .5);
}

.forgot_pass {
    font-size: 12.5px;
    text-decoration: none;
    transition: 300ms;
}

.forgot_pass:hover {
    font-weight: bold;
    cursor: pointer;
}

.text_start {
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 25px;
}

.text_start_alert {
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: initial
}

.div_btn {
    padding-left: 14px;
    padding-right: 14px;
}

.eye {
    background-color: #F9FBFC;
    border: none;
    border-radius: 0px 8px 8px 0px;
    height: 28px;
}

.eyeError{
    background-color: #ffffff;
    border: none;
    border-radius: 0px 8px 8px 0px;
    height: 28px;
}

.eye_icon {
    transition: 0.15s;
    cursor: pointer;
}

.eye_icon:hover {
    opacity: 0.5;
    transition: opacity 0.35s;

}

.error {
    border-style: solid;
    border-width: 1px;
    border-color: #df4759;
}

.error_password {
    border-style: solid;
    border-width: 1px 0px 1px 1px;
    border-color: #df4759;
}

.error_eye {
    border-style: solid;
    border-width: 1px 1px 1px 0px;
    border-color: #df4759;
}

.alert {
    margin-left: 15px;
}

.eyeDivBase{
    margin-top: -2.4rem;
    position: absolute;
    right: 1.8rem;
}

.logo_spacing {
    margin-bottom: 30px;
    margin-top: 30px;
    padding-left: 1rem;
    padding-right: 1rem;
}