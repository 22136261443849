.__popupContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.329);

  display: flex;
  justify-content: center;
  align-items: center;
}

.__popupInner {
  position: relative;
  padding: 32px;
  width: 100%;
  max-width: 640px;
  border-radius: 20px;
  background-color: #ffff;
}

.__title {
  font-weight: 700;
  margin-bottom: 20px;
}

.__subTitle {
  color: #837e7e;
  line-height: 1;
}

.__text {
  color: #837e7e;
  margin-bottom: 20px;
}

.__buttonSize {
  padding: 0px 120px;
}

/* 👇====================================USER DETAILS POPUP=================================👇 */

.__popupContainerUsers {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.329);

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modal_container {
  position: relative;
}

.__popupInnerUsers {
  position: fixed;
  padding: 20px;
  width: 100%;
  height: fit-content;
  max-width: 40%;
  max-height: 90%;
  border-radius: 10px;
  background-color: #fff;
  margin-right: 20px;
  box-shadow: 0px 2px 10px #606060;
  z-index: 1001;
  left: 60%;
  bottom: 4%;
  animation-name: modal-animation;
  animation-duration: 0.5s;
  overflow: hidden;
}

.modal_container>.__popupInnerUsers {
  position: fixed;
  top: 5%;
  left: 47%;
}

.__divExitBtn {
  text-align: end;
  cursor: pointer;
}

.__exitBtn {
  border: none;
  background-color: transparent;
  font-weight: bolder;
}

.__titleRoleUserDetails{
  font-size: 1.563rem;
}

.contract_info {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  margin: auto;
  background-color: #F9FBFC;
  border: none;
  border-radius: 12px;
  text-align: center;
  font-size: 14px;
  padding: 3%;
  margin-bottom: 1rem;
}

h1 {
  font-size: 36px;
  text-align: center;
}

.container {
  overflow-y: scroll;
  padding: 20px;
  width: 100%;
  background-color: #fff;
  margin-right: 20px;
  margin-bottom: 20px;
  border-top: 5px solid #F9FBFC;
  border-bottom: 5px solid #F9FBFC;
}

.containerContractor {
  height: 70vh;
}

.btn_submit {
  max-width: 200px;
  height: 48px;
  border: none;
  transition: 300ms;
  background-color: #444B59;
  transition: ease .3s all;
  border: 2.5px solid #444B59;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, .3); 
  color: white;
}

.btn_submit:hover {
  box-shadow: 0px 8px 5px rgba(0, 0, 0, .3); 
    color: white;
}

.div_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}


@keyframes modal-animation {
  from {
    margin-left: 100%;
  }

  to {
    margin-left: 0;
  }
}

@keyframes modal-closed-animation {
  from {
    margin-left: 0;
  }

  to {
    margin-left: 100%;
  }
}

.__rightIconsDownload {
  margin: 0px .5rem;
  transition: ease 1s all;
  width: 18px;
  height: 18px;
}
.__eyeIcon{
  width: 22px;
  height: 22px;
}


.__AttachmentsRows {
  padding: .8rem 1rem;
  border-bottom: 1px solid #ddd;
}

.__AttachmentsRowsMainTop {
  border-bottom: 2px solid #000;
}

.__noFilesText{
  text-align: center;
  font-size: 1rem;
  color: #444B59;
}

.__rightIconsDownload:hover{
  cursor: pointer;
  color: #444B59;
  width: 22px;
  height: 22px;
}
.__eyeIcon:hover{
  width: 24px;
  height: 24px;
}