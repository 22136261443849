.__buttonsNextPrevious{
    display: flex;
    justify-content: flex-end;
}

.__paginationRow{
    margin-left:.75rem;
    width: calc(100% - 1.5rem);
    border-top: 1px solid rgba(0, 0, 0, 0.12) ;
    
    padding-top: 1rem;
}

.__pagesList{
    color: #877E7E;
    font-size: .8rem;
    margin: 0px .3rem;
    cursor: pointer;
}

.__pagesListSelected{
    color: #000;
    font-size: .8rem;
    font-weight: 700;
    margin: 0px .5rem;
    cursor: pointer;
}

.__buttonPrevious, .__buttonNext{
    color: #877E7E;
    font-size: .8rem;
    display: inline-block;
    cursor: not-allowed;
}

.__buttonNext, .__buttonNextActive{
    margin-left: 2rem;
}

.__buttonIconNext, .__buttonIconPrevious{
    stroke-width: 4 !important; 
}

.__buttonPreviousActive, .__buttonNextActive{
    color: #000;
    font-size: .8rem;
    font-weight: 700;
    display: inline-block;
    cursor: pointer;
}

.__nextPreviousButtonsLoad{
   font-weight: bolder;
   cursor: pointer; 
   margin: 0px 5px;
}


.__tableBase > div > div >div:first-child {
    border-top: solid 1px #000;
    border-bottom: solid 1px #000;
    font-weight:900;
    font-size: .8rem;
    color: #000;
    font-family: 'Roboto', sans-serif !important;
}
.__tableBase > div > div >div:first-child:hover {
    color: #000 !important;
}

.__tableBase div div {
   transition: all .4s ease;
}
.__tableBase > div > div > div > div {
    background-color: transparent !important;
}
.__tableBase > div > div > div > div:hover {
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
    background-color: transparent !important;
}

.__tableBase > div > div > div > div:last-child{
    margin-bottom: 3px;
}

.__tableBase > div > div > div:first-child > div:first-child:hover {
    box-shadow: none !important;
}

.__tableBase > div > div > div:first-child > div:first-child{
    margin-bottom: 0px !important;
}