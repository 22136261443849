.tableItem {
    width: 100%;
    max-width: 24ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.formControl {
    color: black;
    border: none;
}