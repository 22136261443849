.all {
    border: 1px transparent;
    border-radius: .375rem;
    background-color: #f9fbfc;
    font-size: 1rem;
    width: 100%;
    height: 48px;
    padding-left: 15px;
    padding-top: .75rem;
}

.__needPDFLabel{
    font-size: 1rem;
    margin-right: 5px;
}

.btnUpload {
    border: 1px transparent;
    background-color: #5E6B86;
    color: #FFFFFF;
    width: 144px;
    height: 48px;
    border-radius: 0px 8px 8px 0px;
    margin-top: -.75rem;
    float: right;
    text-align: center;
}

.all:hover {
    border: solid 1px #68df68;
}

.__disabled {
    border: 1px transparent;
    border-radius: .375rem;
    background-color: #f9fbfc;
    font-size: 1rem;
    width: 100%;
    height: 48px;
    padding-left: 15px;
    pointer-events: none;

}

.__disabled:hover,
.__disabled:focus {
    border: none;
    outline: none;
    pointer-events: none;

}

.__pMaxSize{
    font-size: .8rem;
    margin-bottom: 0rem;
    margin-top: -.5rem;
    margin-left: .5rem;
}

.__pMaxSizeError{
    color: #DD2E44; 
}

.__inputError{
    border: #DD2E44 solid 1px !important;
    background-color: white;
}