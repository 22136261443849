.__mainStructure{
    background-color: #1F1F1F;
    max-width: 700px;
    width: auto;
    position: absolute;
    z-index: 10000;
    padding: .2rem;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    transition: all .5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.__visibleToast{
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.__disabledToast{
    top: -50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.__baseText{
    color: white;
    width: fit-content;
    display: flex;
    vertical-align: middle;
    font-family: 'Roboto', sans-serif !important;
    font-size: 1.25rem;
    padding: 0 10px;
    white-space: nowrap;
}

.__textCenter{
    display: flex;
    align-items: center;
    justify-content: center;
}

.__iconSuccesfully{
    color: white;
    font-size: 2rem;
    font-weight: bolder;
}
.__iconError{
    color: #C30E0E;
    font-size: 2rem;
    font-weight: bolder;
    margin-top: -1.3rem;
    margin-left: -.9rem;
}

.__baseIcon{
    display: inline-block;
    vertical-align: middle;
    width: 19%;
}

.__IconBack{
    width: 2rem;
    padding: .4rem;
    border-radius: 100%;
    height: 2rem;
    background-color:#68DF68 ;
    display: flex;
    align-items: center;
    justify-content: center;
}

.__IconBackError{
    width: 2rem;
    padding: .4rem;
    border-radius: 100%;
    height: 2rem;
    background-color:#C30E0E ;
    display: flex;
    align-items: center;
    justify-content: center;
}

.__IconBackErrorWhite{
    width: 1rem;
    padding: .4rem;
    border-radius: 100%;
    height: 1rem;
    background-color:white ;
}