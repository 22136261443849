.__MainContent {
    padding: 10px 0px;
    max-width: inherit !important;
}

.__MainTitle {
    padding: 0 0 1rem;
    font-size: 1.563rem;
    font-weight: bold;
}

.__buttonCancel{
    margin: 0px 1rem;
}

.alert {
    padding: 0 .85rem;
}

.alertEmail {
    color: #FB8625;
    padding: 0 .85rem;
}

.alertEmailLink{
    text-decoration:underline;
    cursor: pointer;
    font-weight: 700;
}

.__subTitle {
    padding: 0 0rem 0rem;
    font-size: 1rem;
    font-weight: 800;
}

.__boldText {
    font-weight: 700;
    font-size: 1rem;
}

.paddingSection {
    padding: .5rem .5rem;
}

.textChangePassword{
    margin-top: .5rem;
    font-weight: bold;
    color: #212529;
}

.__contractorInfo {
    background-color: #5E6B86;
    color: white;
    padding: 1.5rem 1rem 1.3rem;
    border-radius: 12px;
    margin-bottom: 1rem;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
}

.__contractInfo{
    background-color: #5E6B86;
    color: white;
    padding: 1.5rem 1rem 1.3rem;
    border-radius: 12px;
    margin-bottom: 1rem;
}

.__contractorInfoInvoice {
    background-color: #5E6B86;
    color: white;
    padding: 1.5rem 1rem 1rem;
    border-radius: 12px;
    margin-bottom: 1rem;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
}

.__buttonPassword {
    margin-left: .5rem;
}

.__rightIcons {
    float: right;
}

.__noFilesText{
    text-align: center;
    font-size: 1rem;
    color: #444B59;
}

.__rightIconsDownload {
    margin: 0px .5rem;
    transition: ease 1s all;
    width: 18px;
    height: 18px;
}
.__eyeIcon{
    width: 22px;
    height: 22px;
}

.__rightIconsDownload:hover{
    cursor: pointer;
    color: #444B59;
    width: 22px;
    height: 22px;
}
.__eyeIcon:hover{
    width: 24px;
    height: 24px;
}

.__rightIconsEye {
    margin: 0px 1rem;
}

.__AttachmentsRows {
    padding: .8rem 1rem;
    border-bottom: 1px solid #ddd;
}

.__AttachmentsRowsMainTop {
    border-bottom: 2px solid #000;
}

.__buttonsSaveCancel {
    margin-top: 2.5rem;
    float: right;
    height: 100px;
}

.__marginButtonsCancel {
    margin: 0px 1.5rem;
}

.__marginButtonsSave {
    margin: 0rem;
}

.__AttachmentsRowsDivMain {
    padding: 0 1rem;
}

.__borderMainDivAttachments{
    border-top: solid #ddd 1px;
    margin: 0 1rem !important;
    padding: 0 !important;
}

.colMyProfileInput {
    margin-bottom: 1rem;
}
.__hourly_rate_suggest{
    text-align: center;
    line-height: 1rem;
}

.colAcitivyInvoice{
    padding-top: 2rem;
    overflow-x: hidden !important;
}

.labelAttachments {
    width: 100%;
    color: black;
    margin-top: -8px;
    margin-bottom: 4px;
    padding-left: 9px;
}

.err {
    color: #DD2E44;
    padding-left: 15px;
}

.errLabel {
    color: #DD2E44;
    font-weight: 700;
}

.errCheck {
    color: #DD2E44;
    padding-top: 1.5rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.__spanCancel {
    background-color: #ffffff;
}

.__spanAproved {
    background-color: #09B01A;
    color: white;
    border-color: transparent;
}

.__spanRejected {
    background-color: #C30E0E;
    color: white;
    border-color: transparent;
}


.__spanSubmitted {
    border: 3px solid #0F7FE7;
    font-weight: 700;
    text-align: center;
}

.__spanApprovedAM {
    border: 3px solid #EFDB2B;
    font-weight: 700;
    text-align: center;
}

.__spanApprovedFinance {
    border: 3px solid #FB8645;
    font-weight: 700;
    text-align: center;
}

.__spanPaid {
    border: 3px solid #09B01A;
    font-weight: 700;
    text-align: center;
}

.__spanRejectedAM {
    border: 3px solid #C30E0E;
    font-weight: 700;
    text-align: center;
}

.__spanRejectedFinance {
    border: 3px solid #C30E0E;
    font-weight: 700;
    text-align: center;
}

.__subbmitedTag{
    background-color: #0F7FE7;
    font-weight: 700;
    color: white;
    text-align: center;
    border-radius: 12px;
    padding: .3rem 3.5rem;
    position: absolute;
    right: -2.5rem;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
}

.__approvedAMTag{
    background-color: #EFDB2B;
    font-weight: 700;
    color: white;
    text-align: center;
    border-radius: 12px;
    padding: .3rem 3.5rem;
    position: absolute;
    right: -2.5rem;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
}
.__approvedFinanceTag{
    background-color: #FB8645;
    font-weight: 700;
    color: white;
    text-align: center;
    border-radius: 12px;
    padding: .3rem 3.5rem;
    position: absolute;
    right: -2.5rem;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
}
.__paidTag{
    background-color: #09B01A;
    font-weight: 700;
    color: white;
    text-align: center;
    border-radius: 12px;
    padding: .3rem 3.5rem;
    position: absolute;
    right: -2.5rem;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
}
.__rejectedTag{
    background-color: #C30E0E;
    font-weight: 700;
    color: white;
    text-align: center;
    border-radius: 12px;
    padding: .3rem 3.5rem;
    position: absolute;
    right: -2.5rem;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
}

.__archivedTag{
    background-color: #877E7E;
    font-weight: 700;
    color: white;
    text-align: center;
    border-radius: 12px;
    padding: .3rem 3.5rem;
    position: absolute;
    right: -2.5rem;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
}

.__theadTable{
    font-weight: bolder;
    font-size: 1rem;
}
.__tbodyTable{
    font-size: 1rem;  
    padding: 0 1em 0 0 !important;
    margin: 0 !important;
}
.__tbodyTablenoCenter{
    font-size: 1rem;
    padding: 0 !important;
    margin: 0 !important;
}

.__tableBase {
    width: 100% !important;
    table-layout: fixed;
  }

  .overflowEllipsis{
    margin-top: 1em;
  }

.__nameColumn {
    width: 16.6% !important;
  }
  
  .__dateColumn {
    width:  16.6% !important;
  }
  
  .__statusColumn {
    width:  16.6% !important;
  }
  
  .__notesColumn {
    width: 50%;
    white-space: normal;
  overflow: visible;
  z-index: 1;
  }
  
  .__notesColumn p {
    width: 100%;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    word-wrap: break-word;
    -webkit-locale: "es";
    text-align: justify;
  }

  .__RoleText{
    margin-top: 1rem !important;
  }