.__MainContent {
    padding: 0px 0px 10px;
    max-width: inherit !important;
}

.__MainTitle {
    padding: 0 0 .5rem;
    font-size: 1.563rem;
    font-weight: bold;
}

.__buttonNew {
    min-width: 12.5rem;
    margin-right: .5rem;
    height: 48px !important;
}

.__checkboxTableSelectALL{
    margin-top: -.3em;
    margin-left: -2.1em;
    position: absolute;
}

.__buttonHeadTable {
    justify-content: flex-end;
    display: flex;
}

.__invoicesSelected{
    font-size: 1em;
    font-weight:500 !important;
}
.__rowInvoicesSelected{
    margin-top: -24px;
    background-color: #444B59;
    color: white;
    margin-left: .8rem;
    width: calc(100% - 1.6rem) !important;
}

.marginBottom{
    margin-bottom: 25px !important;
}

.__rowHeadTable{
    margin-left: 0rem;
    margin-bottom: 1rem;
}

.__buttonHeadTablenoInvoice {
    justify-content: flex-end;
    display: flex;
    margin: 0 1rem .5rem 0;
} 

.__buttonHeadTableButtons{
    justify-content: flex-end;
    display: flex;
}

.__filterSpan{
    margin-left: 20px;
    font-size: 1rem;
    font-weight: bolder;
}

.__SwitchHeadTableButtons{
    width: 200px !important;
    text-align: right;
}

.__buttonFilter{
    max-width: 2.813rem !important;
    min-width: 2.813rem !important;
    min-height: 2.813rem !important;
    max-height: 2.813rem !important;
    border-radius: 12px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0 !important;
    margin-top: .2rem;
    margin-right: .5rem;
    border: 2.5px solid #444B59;
}

.__buttonApprovedGroup{
    color: white !important;
}

.__buttonReload{
    max-width: 2.813rem !important;
    min-width: 2.813rem !important;
    min-height: 2.813rem !important;
    max-height: 2.813rem !important;
    border-radius: 12px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0 !important;
    margin-left: .5rem;
    margin-top: .2em;
    border: 2.5px solid #444B59;
}

.__buttonExport {
    background-color: #444B59;
    max-width: 2.813rem !important;
    min-width: 2.813rem !important;
    min-height: 2.813rem !important;
    max-height: 2.813rem !important;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    transition: ease .3s all;
    margin-top: .2em;
    border: 2.5px solid #444B59;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, .3); 
  }
  
  .__buttonExport:hover {
    box-shadow: 0px 8px 5px rgba(0, 0, 0, .3); 
    color: white;
  }

.__buttonReloadContractor{
    max-width: 48px!important;
    min-width: 48px !important;
    min-height: 48px !important;
    max-height: 48px !important;
    border-radius: 12px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0 !important;
    margin-right: .5rem;
    border: 2.5px solid #444B59;
}


.__rediectMyProfileButton{
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
}


.__filterHeadTable {
    justify-content: flex-end;
    display: flex;
    margin: 0 1rem 0 0;
}

.__statusHeadTable {
    justify-content: flex-end;
    display: flex;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}

.__countStatusLabel {
    color: #877E7E;
    font-size: .8rem;
    margin-left: 1rem;
    cursor: pointer;
}

.__activeCountStatusLabel {
    font-weight: bold;
    color: black;
}

.spanValueTable {
    font-size: .8rem;
}

.spanValueTableDateArchived{
    font-size: .8rem;
    margin-left: 40px;
}

.spanValueTableFolio {
    font-size: .8rem;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.__divCellTable {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -1rem;
    margin-top: .6rem;
    text-align: center;
}

.__checkboxTableColumn{
    margin-right: 5px;
}

.__divCellTableLeft{
        margin-left: -1rem;
        margin-top: .6rem;
        width: 100%;
}

.__divCellTableLeft a{
    text-decoration: none;
    color: black;
    font-weight: bolder;
}

.spanValueTableStatus {
    font-weight: 700;
    width: 7.5rem;
    border-radius: 2rem;
    text-align: center;
}

.__spanSubmitted{
    color: #0F7FE7;
}

.__spanApprovedAM{
    color: #EFDB2B;
}

.__spanApprovedFinance{
    color: #FB8645;
}

.__spanPaid{
    color: #09B01A;
}

.__spanRejectedAM{
    color: #C30E0E;
}

.__spanRejectedFinance{
    color: #C30E0E;
}

.__spanArchived{
    color: #877E7E;
}

.__buttonActionsTable{
    background-color: white;
    color: rgba(0, 0, 0, 0.54);
    border: none;
  }

  .__menuListModal{
    background-color: white;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.4);
  }

  .__menuListItemModal{
    background-color: white;
    border: none !important;
    padding: .5rem 1rem;
    transition: all .2s ease;
  }
  .__menuListItemModal:hover{
    background-color: #ddd;
  }

  .__actionMenuIcons {
    margin-right: 10px;
    color: #1f1f1f;
    font-size: 1em;
  }