.__centerAll{
    text-align: center;
  } 

  .__bgWhite{
    background-color: white;
    width: 100%;
    height: 100vh;
  }

  .__invocieLogo{ 
    width: 350px;
  }

  .__textTitle{
    color: #444B49;
    font-weight: 800;
    font-size: 1.563rem;
  }

  .__textTitleEmail{
    font-weight: 800;
    font-size: 1.563rem;
  }

  .__textSpan{
    font-size: 1rem;
  }

  .__bodyMainDiv{
    margin-bottom: 2.5rem;
  }